@import "@/app/styles/mixings";

.mobileButtonContainer {
  padding: var(--space-4) var(--space-3);
  border-bottom: 1px solid var(--color-border);
  display: none;
  gap: var(--space-2);
  justify-content: flex-end;

  @include respond-above(sm) {
    display: none;
    padding: 0;
    position: fixed;
    left: var(--space-4);
    top: var(--space-4);
  }
}

.desktopThreadSwitcherContainer {
  display: none;
  z-index: 9999;

  @include respond-above(sm) {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: calc(var(--space-4) * -1);
    top: var(--space-5);
  }

  .homeButton {
    z-index: 99999;
    margin-bottom: var(--space-4);
    background: var(--slate-1);
    box-shadow: var(--shadow-2);
  }

  .button {
    z-index: 99999;
    pointer-events: all;
    background: var(--slate-1);
    box-shadow: var(--shadow-2);
  }
}
