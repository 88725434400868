.reactions {
  border-radius: var(--radius-1);
  color: var(--slate-11);

  .reaction {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-1);
    height: 1.25rem;
    background-color: var(--slate-2);
    border-radius: var(--radius-1);
    user-select: none;

    span {
      display: flex;
    }
  }
  .clickable {
    padding: 0 var(--space-2);
    &:hover {
      background-color: var(--slate-3);
    }
  }
}

.reactionsUnexpanded {
  height: 1.25rem;
  border-radius: var(--radius-1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--slate-11);
  gap: var(--space-1);
  background-color: var(--slate-2);

  span {
    display: flex;
  }
}
