.basicEditor {
  width: 100%;
  div[contenteditable="true"] {
    max-height: 20vh;
    overflow: auto;
    color: var(--slate-12);
    box-shadow: inset 0 0 0 1px var(--slate-a7);
    font-size: var(--font-size-3);
    border-radius: var(--radius-4);
    padding: var(--space-2) var(--space-3);
    outline: none;
    &:focus {
      box-shadow:
        inset 0 0 0 1px var(--accent-8),
        0 0 0 1px var(--accent-a8);
    }
  }

  a {
    font-weight: var(--font-weight-medium);
    color: var(--accent-a11);
  }

  [data-type="mention"] {
    font-weight: var(--font-weight-medium);
    background: var(--blue-a3);
    padding: var(--space-1);
    border-radius: var(--radius-3);

    // prefix for channels
    &[data-id^="3"] {
      color: var(--blue-a11);
      background: var(--blue-a3);
    }

    // prefix for users
    &[data-id^="4"] {
      color: var(--indigo-a11);
      background: var(--indigo-a3);
    }
  }

  [data-type="tag"] {
    font-weight: var(--font-weight-medium);
    background: var(--blue-a3);
    padding: var(--space-1);
    border-radius: var(--radius-3);

    color: var(--cyan-a11);
    background: var(--cyan-a3);
  }

  p {
    margin: 0;

    &[data-placeholder]:before {
      content: attr(data-placeholder);
      color: var(--slate-8);
      float: left;
      height: 0;
      pointer-events: none;
    }
  }
}

.basicEditorSkeleton {
  background-color: var(--color-surface);
  box-shadow: inset 0 0 0 1px var(--slate-a7);
  border-radius: var(--radius-4);
  height: 72px;
  width: 100%;
}
