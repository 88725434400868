@import "src/app/styles/mixings";

.threadSkeletonContainer {
  border-bottom: 1px solid var(--slate-4);

  .threadSkeleton {
    display: flex;
    flex-direction: column;
    padding: var(--space-3);
    max-width: 100%;

    @include respond-above(md) {
      padding: var(--space-4) var(--space-8);
      max-width: 50%;
    }

    .title {
      background: var(--slate-3);
      height: var(--line-height-5);
      width: 100%;
      border-radius: var(--radius-2);
    }

    .informationText {
      border-radius: var(--radius-2);
      background: var(--slate-3);
      height: var(--line-height-2);
      width: calc(var(--space-7) * 2);
      margin-right: var(--space-2);
    }
  }
}
