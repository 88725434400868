.roundButtonGroup {
  display: flex;
  &.orientation-horizontal {
    flex-direction: row;
    button:first-of-type {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
    button:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }

  &.orientation-vertical {
    flex-direction: column;
    button:first-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
    button:last-of-type {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: none;
    }
  }
}
