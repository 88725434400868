@import "@/app/styles/mixings";

.lightBoxBackground {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: var(--popup-background);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;

  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }

  .closeBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-font);
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--color-background-transparent);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .navigationButtonLeft,
  .navigationButtonRight {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    border: 0;
    background: var(--color-background-transparent);
    &:hover {
      cursor: pointer;
    }
  }

  .navigationButtonLeft {
    left: 10px;

    @include respond-below(sm) {
      bottom: 10px;
    }
  }

  .navigationButtonRight {
    right: 10px;

    @include respond-below(sm) {
      bottom: 10px;
    }
  }
}
