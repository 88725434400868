.highlight {
  animation: easeOut 2000ms ease-out;
}

.chatMessageContainer {
  border-radius: var(--radius-4);
  position: relative;
  padding: var(--space-1);

  @media (max-width: 768px) {
    padding: var(--space-1) var(--space-4);
  }

  &.selectedChat {
    background: var(--indigo-3);
    &:hover {
      background: var(--indigo-3);
    }

    .checkbox {
      visibility: visible;
    }
  }

  &:hover {
    background: var(--slate-a2);
    .checkbox {
      visibility: visible;
    }
    .toolbar {
      visibility: visible !important;
    }
  }

  .checkbox {
    visibility: hidden;
  }

  .chatMessage {
    display: flex;
    flex-direction: row;
    width: 100%;

    gap: var(--space-4);

    &:hover {
      .timeStamp {
        display: initial;
        color: var(--slate-a8);
      }
    }

    // Always highlighting as there's no identification of edit mode if focus is shift.
    .textarea {
      outline: 2px solid var(--color-focus-root);
      outline-offset: -1px;
    }

    .timeStamp {
      display: none;
    }

    .contentContainer {
      display: flex;
      flex-direction: column;
      flex: 1;
      overflow: hidden;

      .commentEditButtons {
        margin-top: var(--space-2);
        display: flex;
        flex-direction: row;
        gap: var(--space-2);
      }

      .content {
        overflow-wrap: break-word;
        color: var(--slate-12);
        line-height: var(--line-height-2);
      }
    }

    .imageGrid {
      // display: grid;
      display: flex;
      flex-wrap: wrap;
      gap: var(--space-3);
      padding: var(--space-1) 0;
      align-items: center;

      img {
        width: 256px;
        max-height: 256px;
        border-radius: var(--radius-4);
        overflow: hidden;
        object-fit: contain;

        &:hover {
          cursor: zoom-in;
        }

        &.singleImage {

          img {
            max-width: 100%;
            width: auto;
            height: auto;
            max-height: 256px;
            object-fit: fit;
          }
        }
      }

      video {
        max-width: 100%;
        height: 256px;
        border-radius: var(--radius-4);
        overflow: hidden;
        object-fit: contain;
      }
    }
  }
  .toolbar {
    position: absolute;
    top: calc(var(--space-5) * -1);
    right: var(--space-5);
    visibility: hidden;
  }
}

@keyframes easeOut {
  0% {
    background-color: var(--indigo-a4);
    box-shadow: 0 0 0 1px var(--indigo-a6);
  }
  100% {
    background-color: transparent;
    box-shadow: 0 0 0 1px transparent;
  }
}
