.toolbar {
  display: flex;
  border-radius: var(--radius-3);
  background: var(--white-1);
  box-shadow: var(--shadow-3);
  align-items: center;
  overflow: hidden;

  .button {
    border-radius: 0;
    box-shadow: none;
  }
}
