.smallThreadBadge {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  height: var(--space-4);

  .iconContainer {
    display: flex;
    align-items: center;

    .icon {
      border-radius: var(--radius-1);
      margin-right: var(--space-1);
      color: var(--white-1);
      width: var(--space-3);
      height: var(--space-3);
    }
  }
}
