.feedItem {
  padding: var(--space-4) var(--space-2);
  border-bottom: 1px solid var(--slate-4);
  user-select: none;
  // background: var(--white-1);

  .feedCheckbox {
    opacity: 0;
  }

  &:hover {
    background: var(--slate-2);
    .feedCheckbox {
      opacity: 1;
    }
  }

  .header {
    flex: 1;
    min-width: 0;
  }

  .chatMessage {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
}
