.mentionsBadge {
  background: var(--white-1);
  border: 1px solid var(--indigo-a5);
  border-radius: var(--radius-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  height: var(--space-4);

  .text {
    padding: 0 var(--space-2);
  }
}
