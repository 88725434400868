@import "src/app/styles/mixings";

.threadHeader {
  display: flex;
  flex-direction: column;
  padding: var(--space-3);
  position: relative;

  @include respond-above(sm) {
    padding: var(--space-4) var(--space-8);
  }

  .collapsibleButton {
    background: var(--slate-2);
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    bottom: calc(var(--space-5) * -1);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: inset 0 0 0 1px var(--accent-4);
    justify-content: center;
    z-index: 555;
  }

  .collapsibleContent {
    overflow: hidden;
  }
  .collapsibleContent[data-state="open"] {
    animation: slideDown 150ms ease;
  }
  .collapsibleContent[data-state="closed"] {
    animation: slideUp 150ms ease;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-collapsible-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-collapsible-content-height);
    }
    to {
      height: 0;
    }
  }

  .summary {
    border-left: 1px solid var(--indigo-a6);

    .description {
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;

      @include respond-above(sm) {
        max-width: calc(var(--space-9) * 14);
      }

      .tldr {
        display: inline;
        color: var(--indigo-10);
      }

      strong {
        font-weight: 500;
      }

      .s-mention {
        color: red !important;
      }
    }
  }

  .threadFooter {
    color: var(--slate-11);
  }
}
