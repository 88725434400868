@import "src/app/styles/mixings";

.chatMessages {
  overflow-y: scroll;
  flex-grow: 1;
  border-top: 1px solid var(--slate-4);

  .messageList {
    display: flex;
    flex-direction: column;
    padding: var(--space-4) 0 var(--space-3) 0;

    @include respond-above(md) {
      padding: var(--space-4) var(--space-2) var(--space-3) var(--space-2);
    }
  }

  .threadGroup {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: var(--space-4);
    border-top: 1px solid var(--slate-4);

    &:first-child {
      border-top: none;
    }

    .dateGroupHeader {
      position: sticky;
      z-index: 9;
      top: var(--space-3);
      margin: calc(var(--space-3) * -1) auto 0 auto;
      width: auto;
      background: var(--slate-3);

      [data-theme="dark"] & {
        background: var(--d-slate-3);
      }
    }

    .specialStyleForReadUntil {
      background: var(--red-3);

      [data-theme="dark"] & {
        background: var(--d-red-3);
      }
    }

    .dateHeader {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: var(--space-2);

      &:before {
        width: 100%;
        height: 1px;
        background-color: var(--slate-5);
        position: absolute;
        top: calc(var(--space-6) * -1);
        bottom: 0;
        margin: auto 0;
        content: "";
        z-index: 0;
      }
    }
  }
}
