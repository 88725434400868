.content {
  display: flex;
  background: var(--slate-a12);
  border: gray;
  border-radius: var(--radius-2);
  padding: var(--space-2);
  align-items: center;

  .label {
    color: var(--slate-1);
    margin-right: var(--space-2);
  }

  .key {
    background: var(--slate-11);
    color: var(--slate-1);
    padding: 0 var(--space-1);
    border-radius: var(--radius-2);
  }
}
