.threadBadge {
  background: var(--white-1);
  border-radius: var(--radius-2);
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  height: var(--space-4);

  .iconContainer {
    display: flex;
    align-items: center;

    .icon {
      color: white;
      width: var(--space-4);
      height: var(--space-4);
    }
  }

  .text {
    padding: 0 var(--space-2);
  }
}
