.toggleContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--slate-3);
  border-radius: var(--radius-4);
  border: 1px solid var(--slate-6);
  width: 100%;

  .floatingBox {
    position: absolute;
    // width: 49%;
    height: var(--space-5);
    background-color: var(--white-1);
    border-radius: var(--radius-4);
    transition: transform 0.2s ease;
    border: 1px solid var(--indigo-a6);
    z-index: 1;
    box-shadow: var(--shadow-3);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100%;
    padding: 0 var(--space-3);
    height: var(--space-5);
    position: relative;
    z-index: 2;
    color: var(--slate-11);
    margin-left: -1px;

    &.active {
      color: var(--indigo-10);
    }
  }
}
